#login {
    background: linear-gradient(111deg, rgba(144, 50, 109, 0.5) 0%, rgba(255, 255, 255, 0) 50%, rgba(15, 103, 203, 0.35) 100%) 0% 0% / 100% 100% no-repeat, 
                linear-gradient(249deg, rgba(144, 50, 109, 0.5) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(15, 102, 203, 0.35) 100%) 0% 0% / 100% 100% no-repeat;
    height: 100vh;
    width: 100vw;

    .auth-container {
        height: fit-content;
        min-width: 28%;
        padding: 24px;
        border-radius: 8px;

        .auth-logo {
            max-height: 100px;
        }
    }

    .auth-step-1,
    .auth-step-2,
    .auth-step-3 {
        width: 100%;
    }

    .auth-step-2 {
        .react-tel-input {
            .form-control.auth-phone-input {
                &:focus {
                    border-color: #252525;
                    box-shadow: none;
                }
            }
            
        }
    }

    .auth-step-3 {
        .auth-step-3-content {
            width: 100%;
        }
    }

    .auth-step-prompt {
        margin-bottom: 8px;
    }
}