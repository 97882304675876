#sidenav {
    .sidenav-tab-link {
        text-decoration: none;

        &.active {
            .MuiButtonBase-root.MuiListItemButton-root {
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 20%;
                    width: 4px;
                    height: 60%;
                    border-radius: 0 4px 4px 0;
                    background-color: var(--PRIMARY_COLOR);
                }

                background-color: var(--ACCENT_COLOR);
                
                .MuiListItemIcon-root,
                .MuiListItemText-root .MuiListItemText-primary {
                    color: var(--PRIMARY_COLOR);
                }
            }
        }

        .MuiTouchRipple-root .MuiTouchRipple-child {
            background-color: var(--PRIMARY_COLOR);
        }
    }
}
