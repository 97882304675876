@import './global.css';

.App {
    .side-nav-wr { 
        height: 10vh;
    }

    .site-content-wr {
        background-color: var(--BACKGROUND_GREY);
        border-radius: 12px;
        margin-right: 20px;
        margin-bottom: 20px;
        overflow: auto;
    }
}
