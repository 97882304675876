:root {
    --PRIMARY_COLOR: #9e2d63;
    --ACCENT_COLOR: #F5EDF1;

    --BACKGROUND_GREY: #F7f7F7;

    --secondary-color: #0f66cb;
    --accent-color: #f5edf1;

    --widget-color: #ffffff;
    --bg-color: #f7f8f9;
    --font-color: #282828;
}

