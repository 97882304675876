@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url("react-toastify/dist/ReactToastify.css");
@import './variables.css';

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: "white";
}

.icon {
  color: #4b4b4b;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  border-radius: 2px;
}