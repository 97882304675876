.MuiPaper-root.MuiPopover-paper {
    max-height: 64vh;

    .MuiFormControl-root {
        .MuiFormGroup-root {
            gap: 8px;

            .MuiFormControlLabel-root.filter-radio {
                margin-left: -8px;
                margin-right: -8px;
                
                .MuiFormControlLabel-label {
                    font-size: 14px;
                    padding: 0 8px;
                }

                &:hover {
                    background-color: #F5EDF1;
                    border-radius: 8px;
                }
            }
        }
    }
}

.date-range-popover {
    .MuiPaper-root.MuiPopover-paper {
        .rdrDefinedRangesWrapper {
            border-color: #f5f5f5;

            .rdrStaticRange, 
            .rdrInputRange .rdrInputRangeInput {
                color: #4b4b4b;
                border-color: #f5f5f5;
            }

            .rdrStaticRange:hover, 
            .rdrStaticRange:focus {
                .rdrStaticRangeLabel {
                    background: #f5f5f5;
                }
            }

            .rdrStaticRangeSelected .rdrStaticRangeLabel {
                color: #9e2d63;
            }
        }

        .rdrDateRangeWrapper.rdrDateRangeWrapper {
            .rdrDateDisplayWrapper {
                background-color: #f5f5f5;

                .rdrDateInput {
                    input {
                        color: #4b4b4b;
                    }
                }
            }

            .rdrMonthAndYearWrapper {
                .rdrNextPrevButton {
                    background: #f5f5f5;
                }

                .rdrNextButton i {
                    border-color: transparent transparent transparent #4b4b4b;
                }

                .rdrPprevButton i {
                    border-color: transparent #4b4b4b transparent transparent
                }

                .rdrMonthAndYearPickers {
                    .rdrMonthPicker,
                    .rdrYearPicker {
                        select {
                            color: #4b4b4b;
                        }
                    }
                }
            }

            .rdrMonths.rdrMonthsHorizontal {
                .rdrMonth {
                    .rdrMonthName, .rdrWeekDays {
                        color: #979797;
                    }

                    .rdrDays {
                        .rdrDayPassive .rdrDayNumber span {
                            color: #d0d0d0;
                        }

                        .rdrDayNumber span {
                            color: #4b4b4b;
                        }

                        .rdrDay {
                            .rdrDayStartPreview,
                            .rdrDayEndPreview,
                            .rdrDayInPreview {
                            border-color: #9e2d63;
                            }
                        }

                        .rdrDayToday .rdrDayNumber span:after {
                            background: #9e2d63;
                        }
                    }
                }
            }
        }
    }
}